<template>
  <div class="blog" :class="{loading: loading}">
    <div class="columns is-multiline is-mobile">
      <Article :article="article" v-for="(article, i) in articles" :key="i"/>
    </div>
    <div class="loader" v-if="loading"/>
  </div>
</template>

<script>
import axios from 'axios'
import Article from './Article.vue'

export default {
  name: 'Blog',
  components: {
    Article
  },

  data () {
    return {
      articles: [],
      loading: false
    }
  },

  methods: {
    refresh () {
      const url = 'https://api.fabiomanganiello.com/api/blog'
      this.loading = true
      this.articles = []
      const self = this

      axios.get(url).then((response) => {
        self.articles = self.articles.concat(response.data)
      })
    }
  },

  created () {
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
.blog {
  height: 100%;
  padding: 0 2em;
}

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
