<template>
  <div class="github" :class="{loading: loading}">
    <div class="columns is-multiline is-mobile">
      <Project :project="project" v-for="(project, i) in projects" :key="i"/>
    </div>
    <div class="loader" v-if="loading"/>
  </div>
</template>

<script>
import axios from 'axios'
import Project from './Project.vue'

export default {
  name: 'Github',
  components: {
    Project
  },

  data () {
    return {
      loading: false,
      projects: []
    }
  },

  methods: {
    refresh () {
      const url = 'https://api.fabiomanganiello.com/api/projects'
      const self = this
      self.loading = true

      axios.get(url).then((response) => {
        self.projects = response.data
      }).finally(() => {
        self.loading = false
      })
    }
  },

  created () {
    this.loading = true
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
.github {
  height: 100%;
  max-width: 70em;
  padding: 0 2em;
  margin: auto;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
