import Vue from 'vue'
import App from './App.vue'
import './../node_modules/bulma/css/bulma.css'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  data: {
    selectedTab: undefined
  }
}).$mount('#app')

// vim:sw=2:ts=2:et:
