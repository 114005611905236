'use strict'

export default {
  htmlEntities (str) {
    return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;')
  },

  date (d) {
    return new Date(d).toDateString().substr(4)
  }
}
