<template>
  <div class="container column is-full-mobile is-half-tablet is-one-third-desktop">
    <a :href="tutorial.url" target="_blank" class="tutorial">
      <div class="head">
        <div class="name" v-text="tutorial.name"/>
        <div class="lang">
          <flag :iso="tutorial.lang"/>
        </div>
      </div>
      <div class="description" v-text="tutorial.description"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Tutorial',
  props: {
    tutorial: Object
  }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  box-shadow: 0px 1px 3px 1px #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 4px 3px #999;
  }
}

.tutorial {
  width: 100%;
  height: 100%;
  min-height: 10em;
  position: relative;
}

.head {
  width: 100%;
  display: flex;
  margin-top: .4em;

  .name {
    font-size: 1.2em;
    width: 80%;
  }

  .lang {
    width: 20%;
    text-align: right;
  }
}

.description {
  color: rgba(0, 0, 0, 0.7);
  font-size: .9em;
  margin-top: .5em;
  margin-bottom: .5em;
}

a {
  color: initial;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
