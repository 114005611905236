<template>
  <div class="container">
    <div class="about">
      <div class="head">
        <div class="image">
          <img src="/img/profile_pic.png">
        </div>
        <div class="name">
          <h2>Fabio Manganiello</h2>
        </div>
      </div>

      <div class="body">
        I am a science, engineering and music geek who likes to build things
        that solve problems.  I have a background in computer engineering, and
        had the chance to touch many aspects of this world over the years. My
        <a href="/pdf/BS_thesis.pdf" target="_blank">Bachelor thesis</a> was on
        semantic technologies and expert systems, with a particular focus on
        inferring meaning and definitions of unknown terms provided in the
        context of large sets of natural language documents. I worked in the
        field of semantic technologies for a while before shifting my focus to
        other areas of IT.<br/><br/>

        I've been close to the "underground" IT security world since about
        2001. Some who were around at the time may also remember it as
        <tt>blacklight.gotdns.org</tt> or <tt>0x00.ath.cx</tt>, and it was
        hosted for a long time on an old Pentium 1 (later upgraded to a Pentium
        3) under my bed. In those years I used to build several tools for IT
        security (traffic analyzers, shellcode generators, exploits, as well as
        tools for reverse engineering, ARP/DNS poisoning and intrusion
        detection), and I created and used to run <a
        href="https://web.archive.org/web/20100526053448/http://blacklight.gotdns.org/wiki/index.php/Pagina_principale"
        target="_blank">HacKnowledge</a>, an ambitious wiki that covered many
        aspects of IT security (unfortunately it's been lost when the hard
        drives of my Pentium 3 got fried).<br/><br/>

        My <a href="/pdf/MS_thesis.pdf" target="_blank">Master thesis</a>
        described the theory, design and implementation of my early <a
        href="https://github.com/BlackLight/Snort_AIPreproc"
        target="_blank">Snort module</a> that leveraged machine learning
        algorithms for clustering and inferring correlations among large sets
        of security alerts, as well as infer possible next actions in
        multi-step attacks scenarios.  I've published several research papers on
        the topics of clustering, correlating and predicting alerts in
        scenarios of multi-step attacks.<br/><br/>

        I am currently based in the Netherlands, where I work as a senior
        engineer for Booking.com's machine learning and big data
        infrastructure.<br/><br/>

        I have also been on top of the IoT and automation wave for several
        years.  I built <a href="https://github.com/BlackLight/Voxifera"
        target="_blank">Voxifera</a> back in 2008, as an early experiment on
        voice assistants, but it's been thanks to the wide diffusion of faster
        networks, better embedded devices and players like RaspberryPi and
        Arduino that I've really managed to get more out of this world. I
        started working on what today is <a
        href="https://platypush.tech">Platypush</a> in the early 2010s with the
        aim of building a powerful and general-purpose platform to administer,
        control and connect together smart devices, cloud services and custom
        scripts.
        <br/><br/>

        I <a href="https://blog.platypush.tech" target="_blank">run a blog</a>
        where every now and then I write about automation projects involving
        Platypush - from setting up my own media center, to controlling a
        plethora of Zigbee/Z-Wave/Bluetooth smart devices, to detecting the
        presence of people in a room, to building custom voice assistants, to
        piloting my self-built robots and drones.  My work on IoT and
        automation has been rewarded with the <a
        href="https://noonies.tech/award/hacker-noon-contributor-of-the-year-iot"
        target="_blank">HackerNoon IoT Contributor of the Year 2020 Award</a>,
        and I have <a href="https://www.apress.com/gp/book/9781484268209"
        target="_blank">published a book</a> that takes the reader through the
        foundations of machine learning and shows how to set up simple computer
        vision algorithms on a Raspberry Pi.<br/><br/>

        I'm also an enthusiastic part-time musician, originally trained on
        piano, bread and classical music, although I'm mostly a guitar guy
        lately. I have participated in many projects, recordings and live acts
        over the years, ranging from blues, progressive rock, post-rock, jazz,
        Indian music and electronic music. Lately I work mostly solo though,
        you can find some of my tracks <a
        href="https://my.music.fabiomanganiello.com"
        target="_blank">here</a>, or on <a
        href="https://open.spotify.com/artist/5H6BJfHec8qLnkm0S2HkxO"
        target="_blank">Spotify</a>, and there's probably more stuff
        lost in other corners of the Internet.<br/><br/>

        When I don't code and I don't play music you may find me surfing,
        riding a skate or enjoying some good craft beer.<br/><br/>

        <h3>Contacts</h3>

        <ul>
          <li> <a rel="me" href="https://manganiello.social/users/fabio" target="_blank">Fediverse</a>: <code>@fabio@manganiello.social</code></li>
          <li> <a href="xmpp://fabio@manganiello.tech">XMPP/Mail</a>: <code>fabio@manganiello.tech</code></li>
          <li> <a href="https://matrix.to/#/@blacklight:matrix.platypush.tech">Matrix</a>: <code>@blacklight:matrix.platypush.tech</code></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About'
}
</script>

<style scoped lang="scss">
.container {
  height: calc(100vh - 4em);
  display: flex;
  justify-content: center;
}

.about {
  max-width: 70em;
  padding: 0 2em;
  text-align: center;
}

.head {
  display: flex;
  flex-direction:row;
  align-items: center;

  .image {
    img {
      width: 8em;
    }
  }
}

h2 {
  font-size: 1.6em;
  margin-left: 2em;
}

h3 {
  font-size: 1.3em;
  margin-bottom: 1em;
}

ul {
  margin: 1em 0;

  li {
    list-style: initial;
  }
}

.body {
  margin-top: 2em;
  text-align: left;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
