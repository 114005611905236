<template>
  <div class="menu">
    <div class="mobile-menu-button is-hidden-desktop">
      <button type="button" @click="displayMobileMenu = !displayMobileMenu">
        <i class="fas fa-bars"/>
      </button>

      <div class="site-title">
        Fabio Manganiello
      </div>
    </div>

    <div class="desktop-menu is-hidden-touch">
      <div class="links">
        <a :href="'#' + name" class="item" :class="{selected: name === selectedPage}"
            v-for="(page, name) in pages" :key="name" v-text="name.toUpperCase()"
            @click="bus.$emit('page-click', name)">
        </a>
      </div>

      <div class="feed-icon-container">
        <a href="/feed" target="_blank" title="RSS feed">
          <div class="feed-icon"></div>
        </a>
      </div>
    </div>

    <div class="mobile-menu is-hidden-desktop" :class="{'is-hidden': !displayMobileMenu}">
      <a :href="'#' + name" class="item" :class="{selected: name === selectedPage}"
          v-for="(page, name) in pages" :key="name" v-text="name.toUpperCase()"
          @click="mobileMenuClick(name)" />
      <a href="/feed" class="item" title="RSS Feed">
        RSS FEED
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    bus: Object,
    pages: Object,
    selectedPage: String
  },

  data () {
    return {
      displayMobileMenu: false
    }
  },

  methods: {
    mobileMenuClick (name) {
      this.bus.$emit('page-click', name)
      this.displayMobileMenu = false
    }
  }
}
</script>

<style scoped lang="scss">
.menu {
  text-align: left;
  display: flex;
  align-items: center;
  width: 100%;
  height: 4em;
  top: 0;
  padding: .25em 0;
  box-shadow: 0 4px 6px #ccc;
  position: fixed;
  z-index: 10;
  background: white;
}

.desktop-menu {
  width: 100%;
  display: flex;
  align-items: center;

  .feed-icon-container {
    text-align: right;
    flex-grow: 1;
  }
}

.feed-icon {
  background: url(https://blog.fabiomanganiello.com/img/rss.png);
  background-size: 40px !important;
  width: 40px;
  height: 40px;
  display: inline-flex;
}

.mobile-menu-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    width: 1.8em;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: .3em;
    border-radius: .5em;
    background: white;
    font-size: 1.6em;
    border: 0;
    margin-left: .3em;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }

  .site-title {
    width: 90%;
    text-align: right;
    font-size: 1.6em;
    margin-right: .5em;
  }
}

.item {
  color: #2c3e50;
  padding: 0 1em;
  cursor: pointer;

  &.selected {
    font-weight: bold;
  }

  &:hover {
    padding-bottom: .5em;
  }
}

.mobile-menu {
  position: absolute;
  top: 4em;
  height: calc(100vh - 4em);
  background: rgba(30, 34, 50);
  display: flex;
  flex-direction: column;
  z-index: 10;
  padding: .5em 0;
  overflow: auto;

  a {
    color: white;
  }

  .item {
    padding: 1em 5em 1em 1em;
    border-bottom: 1px solid #444;

    &:hover {
      opacity: .5;
    }
  }
}
</style>

<!-- vim:sw=2:ts=2:et: -->
