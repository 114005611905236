<template>
  <div id="app">
    <Menu :pages="pages" :selectedPage="selectedPage" :bus="bus"/>

    <main>
      <Blog v-if="selectedPage === 'blog'"/>
      <Github v-if="selectedPage === 'projects'"/>
      <Tutorials v-if="selectedPage === 'tutorials'"/>
      <Publications v-if="selectedPage === 'publications'"/>
      <Social v-if="selectedPage === 'social'"/>
      <Music v-if="selectedPage === 'music'"/>
      <About v-if="selectedPage === 'about'"/>
    </main>
  </div>
</template>

<script>
import Vue from 'vue'
import Menu from './components/Menu.vue'
import Blog from './components/blog/Blog.vue'
import Github from './components/github/Github.vue'
import Tutorials from './components/tutorials/Tutorials.vue'
import Publications from './components/pubs/Publications.vue'
import Music from './components/music/Music.vue'
import About from './components/about/About.vue'
import Social from './components/social/Social.vue'

import FlagIcon from 'vue-flag-icon'
Vue.use(FlagIcon)

export default {
  name: 'App',
  components: {
    Menu,
    Blog,
    Github,
    Tutorials,
    Publications,
    Music,
    Social,
    About
  },

  data () {
    return {
      bus: new Vue({}),
      selectedPage: 'blog',
      pages: {
        blog: Blog,
        projects: Github,
        tutorials: Tutorials,
        publications: Publications,
        social: Social,
        music: Music,
        about: About
      }
    }
  },

  created () {
    const m = window.location.href.match('#([a-zA-Z0-9._]+)$')
    if (m) {
      this.selectedPage = m[1]
    }
  },

  mounted () {
    this.bus.$on('page-click', (page) => {
      this.selectedPage = page
    })
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  position: relative;
}

main {
  padding-top: 6.5em;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader {
  font-size: 2em;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
