<template>
  <a :href="article.url" target="_blank" class="article column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-one-fifth-fullhd">
    <div class="container">
      <div class="image">
        <img :src="article.imageUrl">
      </div>

      <div class="title" v-text="article.title"/>
      <div class="date" v-text="date"/>
      <div class="description" v-text="article.description"/>
    </div>
  </a>
</template>

<script>
import utils from '../../plugins/utils.js'

export default {
  name: 'Article',
  props: {
    article: Object
  },

  computed: {
    date () {
      return utils.date(this.article.createdAt)
    }
  }
}
</script>

<style scoped lang="scss">
.article {
  display: block;
  box-shadow: 0px 1px 3px 1px #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 30em;

  &:hover {
    box-shadow: 0px 1px 4px 3px #999;
  }
}

.container {
  height: 100%;
  padding: 2em;
}

.image {
  height: 35%;
  z-index: 1;
  img {
    height: 100%;
  }
}

.title {
  font-size: 1.6em;
  margin-top: .4em;
}

.description {
  color: rgba(0, 0, 0, 0.7);
}

.date {
  color: rgba(0, 0, 0, 0.4);
  margin: -1.25em 0 1.5em 0;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: initial;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
