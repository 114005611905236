<template>
  <div class="social-container">
    <iframe ref="iframe"
      allowfullscreen
      sandbox="allow-top-navigation allow-scripts"
      width="100%" height="100%"
      src="https://www.mastofeed.com/apiv2/feed?userurl=https%3A%2F%2Fmanganiello.social%2Fusers%2Ffabio&theme=light&size=100&header=true&replies=false&boosts=false">
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'Social'
}
</script>

<style lang="scss" scoped>
.social-container {
  width: 100%;
  height: calc(100vh - 4em);
  display: flex;
  overflow: auto;
  margin: -2.5em auto 0 auto;
}
</style>
