<template>
  <div class="pubs" :class="{loading: loading}">
    <div class="columns is-multiline is-mobile">
      <Publication :publication="publication" v-for="(publication, i) in publications" :key="i"/>
    </div>
    <div class="loader" v-if="loading"/>
  </div>
</template>

<script>
import axios from 'axios'
import Publication from './Publication.vue'

export default {
  name: 'Publications',
  components: {
    Publication
  },

  data () {
    return {
      loading: false,
      publications: []
    }
  },

  methods: {
    refresh () {
      const url = '/api/pubs.json'
      const self = this

      axios.get(url).then((response) => {
        self.publications = self.publications.concat(response.data)
      }).finally(() => {
        self.loading = false
      })
    }
  },

  created () {
    this.loading = true
    this.refresh()
  }
}
</script>

<style scoped lang="scss">
.pubs {
  height: 100%;
  max-width: 70em;
  padding: 0 2em;
  margin: auto;
}

.column {
  margin: 0;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
