<template>
  <div class="container column is-full-mobile is-half-tablet is-one-third-desktop">
    <a :href="project.html_url" target="_blank" class="project">
      <div class="head">
        <div class="name" v-text="project.name"/>

        <div class="stats">
          <div class="stars stat">
            <i class="far fa-star"/> {{project.stargazers_count}}
          </div>
          <div class="forks stat">
            <i class="fas fa-code-branch"/> {{project.forks_count}}
          </div>
        </div>
      </div>

      <div class="description" v-text="project.description"/>

      <div class="bottom">
        <div class="date-container">
          <div class="date-label">
            Created on
          </div>
          <div class="date" v-text="createdAt"/>
        </div>
        <div class="date-container">
          <div class="date-label">
            Updated on
          </div>
          <div class="date" v-text="updatedAt"/>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
import utils from '../../plugins/utils.js'

export default {
  name: 'Project',
  props: {
    project: Object
  },

  computed: {
    createdAt () {
      return utils.date(this.project.created_at)
    },

    updatedAt () {
      return utils.date(this.project.updated_at)
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  box-shadow: 0px 1px 3px 1px #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 4px 3px #999;
  }
}

.column {
  margin: 0;
}

.project {
  width: 100%;
  height: 100%;
  min-height: 10em;
  position: relative;
}

.head {
  width: 100%;
  display: flex;
  align-items: center;

  .name {
    font-size: 1.2em;
    margin-top: .4em;
    width: 60%;
  }

  .stats {
    width: 40%;
    text-align: right;
    color: rgba(0, 0, 0, 0.6);

    .stat {
      display: inline-flex;
      margin: .5em;
      align-items: center;

      i {
        margin-right: .4em;
      }
    }
  }
}

.description {
  color: rgba(0, 0, 0, 0.7);
  font-size: .9em;
  margin-top: .5em;
  margin-bottom: .5em;
}

.bottom {
  width: 100%;
  position: absolute;
  background: white;
  font-size: .8em;
  bottom: 0;
  z-index: 9;
}

.date-container {
  display: flex;
  .date-label {
    width: 50%;
  }

  .date {
    width: 50%;
    color: rgba(0, 0, 0, 0.4);
    text-align: right;
  }
}

a {
  color: initial;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
