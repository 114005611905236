<template>
  <div class="container column is-full-mobile is-half-tablet is-one-third-desktop">
    <a :href="publication.url" target="_blank" class="publication">
      <div class="head">
        <div class="name" v-text="publication.name"/>
        <div class="publisher" v-if="publication.publisher">
          Published in {{publication.publisher}}
        </div>
      </div>

      <div class="abstract" v-text="publication.abstract"/>
    </a>
  </div>
</template>

<script>
export default {
  name: 'Publication',
  props: {
    publication: Object
  }
}
</script>

<style scoped lang="scss">
.container {
  display: flex;
  box-shadow: 0px 1px 3px 1px #aaa;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 4px 3px #999;
  }
}

.publication {
  width: 100%;
  height: 100%;
  min-height: 10em;
  position: relative;
}

.head {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: .4em;

  .name {
    font-size: 1.2em;
    width: 80%;
  }

  .publisher {
    margin-top: .3em;
    font-size: .8em;
    color: rgba(0, 0, 0, 0.65);
  }
}

.abstract {
  color: rgba(0, 0, 0, 0.8);
  font-size: .9em;
  margin: 1em auto .5em auto;
}

a {
  color: initial;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
