<template>
  <div class="music">
    <div class="columns is-multiline is-mobile">
      <iframe width="100%" height="330" scrolling="no" frameborder="no" src="https://music.fabiomanganiello.com/front/embed.html?type=playlist&id=1"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Music'
}
</script>

<style scoped lang="scss">
.music {
  height: calc(100vh - 4em);
  margin-top: -2.5em;
}

.columns {
  height: 100%;
  margin: 0;
}

iframe {
  width: 100%;
  height: 100%;
}
</style>

<!-- vim:sw=2:ts=2:et: -->
